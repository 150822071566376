<template>
    <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/liveManage"
          >直播管理 / </router-link>
          <span class="crumbs_item crumbs_last">上传回放</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <span class="all_left_name all_required">上传直播回放</span>
        <div>
          <a-upload
              list-type="picture-card"
              class="uploader-video"
              :show-upload-list="false"
              action="/api/uploadVideo"
              accept=".mp4"
              :before-upload="beforeUploadVideo"
              @change="handleChangeVideo"
              :data="{
                dirName: 'media'
              }"
            >
              <img v-if="videoFile" :src="videoFile" alt="mp4" />
              <div v-else>
                <a-icon :type="videoLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">上传视频</div>
              </div>
            </a-upload>
        </div>
      </div>
      <!-- <div class="Up_Down_inner">
        <div class="all_left_name all_required">视频有效期</div>
        <div>
          <a-input
            class="all_input"
          /> 天
          <a-radio class="left">永久</a-radio>
        </div>
      </div> -->

    </div>
    <div class="button">
      <a-button
        type="primary"
        class="btn"
      >确认</a-button>
      <a-button class="all_boder_btn btn">取消</a-button>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: { }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
       // 视频地址
      videoLoading: false,
      videoFile: '',
     };
  }, // 事件处理器
  methods: {
     // 视频上传 - 上传开始前
    beforeUploadVideo(file){
      if (!this.$regular.videoReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.videoReg.msg);
        return false;
      }
      this.$set(this, 'videoLoading', true)
    },
    // 视频上传 - 上传结束后
    handleChangeVideo({ file }){
      if (file.status === 'done' || file.status === 'removed') {
        this.$set(this, 'videoLoading', false)
        this.videoFile = file
      }
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    // this.userType = this.$route.query.userType;
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }
  .all_content_box {
    .vue-ueditor-wrap[data-v-1e1388a0] {
      width: 800px;
    }
    .Up_Down_inner {
      margin-top: 24px;
      display: block;
      .all_input {
        width: 197px;
      }
      .left{
        margin-left: 24px;
      }
    }
  }
  .button {
    .btn {
      margin: 36px 14px;
    }
  }
}
</style>
